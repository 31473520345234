<template>
  <div>
    
   <h1>ご案内</h1>
    <p>コンテンツ内容をここに</p>



  </div>
</template>

<script>
export default  {
    created: function() { this.$parent.middle = 2; }}
</script>

<style scoped>
</style>